import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/Main/Main";
import CalcPageComponent from "./components/CalcPageComponent/CalcPageComponent";
import BankPageComponent from "./components/BankPageComponent/BankPageComponent";
import ListComponent from "./components/ListComponent/ListComponent";
import RouteChangeTracker from "./components/RouteChangeTracker/RouteChangeTracker";
import MetaTagSet from "./components/Header/MetaTag";

function App() {
  return (
    <>
      <MetaTagSet
        title="셈셈 :: 부동산계산기"
        description="부동산 거래 시 발생하는 양도세, 보유세, 중개보수, 취득세 등 다양한 세금을 쉽게 계산할 수 있는 웹사이트입니다."
        keywords="부동산 세금 계산기, 양도세 계산기, 보유세 계산기, 중개보수 계산기, 취득세 계산기, 부동산 거래 세금, 부동산 세금 안내, 부동산 세금 절세 방법, 부동산 거래 비용 계산"
        url={process.env.PUBLIC_URL}
        imgsrc={process.env.PUBLIC_URL + "/logo_row.png"}
      />
      <Router>
        <RouteChangeTracker />
        <Routes>
          <Route path="/" element={<Main />}>
            <Route index element={<ListComponent />} />
            <Route path="calc/:id" element={<CalcPageComponent />} />
            <Route path="rate/:id" element={<BankPageComponent />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
