export const slideItem = [
  {
    company: "DAEYEON P&C",
    content: 'Villa de Mont:\n"속초의 품격 있는\n라이프스타일을 누리다."',
    img: "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/9af72eb5-bfa9-4028-930d-72a243e01f00/calcslider",
    url: "http://daeyeonpnc.com/",
  },
  {
    company: "DAEYEON P&C",
    content: 'PIANO FORTE\n"바다의 속삭임을 듣는 집\n도심 속 휴양지를 꿈꾸다."',
    img: "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/4b2b8821-90e2-4826-2306-0bef483cd600/calcslider",
    url: "qweqwe",
  },
  {
    company: "Return Plus",
    content: 'Return Plus:\n"더 빠르고 스마트한\n자산 관리의 시작"',
    img: "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/2f1b4e61-af28-4d06-f690-151669fb5100/calcslider",
    url: "qweqwe",
  },
  {
    company: "Han Jogak",
    content:
      'Han Jogak:\n"고가 자산의 디지털 혁신,\n누구에게나 열린 투자 기회"',
    img: "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/2f1b4e61-af28-4d06-f690-151669fb5100/calcslider",
    url: "qweqwe",
  },
];

export const categories = [
  {
    name: "중개보수",
    description: "부동산 중개수수료(복비)",
    id: "Brokerage",
    img: "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/93d9782a-93d9-4f8b-2c44-dbf22b296a00/calcmain",
  },
  {
    name: "양도세",
    description: "부동산 시세 차익에 대한 세금",
    id: "CapitalGainsTax",
    img: "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/e629e012-f629-41e9-cc53-58bbf5249d00/calcmain",
  },
  {
    name: "보유세",
    description: "재산세, 종합부동산세 동시 계산",
    id: "HoldingTax",
    img: "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/6068f0ff-7184-48b3-17af-87fe59d0db00/calcmain",
  },
  {
    name: "취득세",
    description: "매매·증여·상속 등 취득 시 세금",
    id: "AcquisitionTax",
    img: "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/0f7ab798-1b8b-4fd0-746c-720def4aa900/calcmain",
  },
];

export const bankCategories = [
  {
    name: "예금 금리",
    description: "국내 예금 금리 총정리",
    id: "DepositRate",
    img: "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/ed411cff-b46c-4d20-2bc5-55cc81cc2800/calcmain",
  },
  {
    name: "대출 금리",
    description: "국내 대출 금리 정리",
    id: "LoanRate",
    img: "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/e3615370-5b9e-496f-e8a3-8903ccd6c000/calcmain",
  },
];

export const holdingTaxMap = {
  설명: `## 보유세란? 
> 주택, 토지 등 부동산을 보유한 경우 부담하게 되는 세금인 종합부동산세와 재산세를 합하여 보유세라고 합니다. 보유세 통합 계산기에서는 주택에 부과되는 종합부동산세와 재산세를 한 번에 편리하게 확인할 수 있습니다. 각 세목에 대한 더 구체적인 계산내역이나 주택 외 다른 부동산에 대한 계산은 종합부동산세 계산기와 재산세 계산기를 참고해주시기 바랍니다.

## 부동산대책 및 개정세법 반영 안내

2023년 세법개정과 2024년 1월·2월 및 5월 세법개정이 종합부동산세와 재산세에 미치는 영향을 모두 보유세 통합 계산기에 반영하였습니다. 부동산대책과 개정세법이 다루고 있는 종합부동산세와 재산세에 대한 자세한 내용은 종합부동산세 계산기의 '세법개정'과 재산세 계산기의 '세법개정'에서 확인해주시기 바랍니다.

## 계산기 사용방법

#### 1) 공시가격(시가표준액) 확인 : 종합부동산세와 재산세는 시세가 아닌 공시가격(시가표준액)을 기준으로 과세합니다.

- 단독주택 : 개별단독주택공시가격 열람
- 공동주택(아파트 등) : 공동주택공시가격 열람

#### 2) 1세대 1주택자 판단 : 종합부동산세법에서의 1세대 1주택자란 과세기준일 현재 세대원 중 1인이 주택 한 채를 단독으로 소유한 경우나 부부공동명의 1주택자 특례 또는 일시적 2주택 등 1세대 1주택자 특례를 신청하여 1세대 1주택자로 인정되는 경우를 말합니다. 1세대 1주택자는 종합부동산세 계산 시 공시가격 합산액에서 11억원(2023년 납부분부터 12억원)을 공제받으며, 납세의무자가 만 60세 이상이거나 주택을 5년 이상 보유하였다면 추가로 연령·보유기간 세액공제도 받을 수 있습니다.

- 부부공동명의 1주택자 특례 : 2021년 1월 종합부동산세법 개정으로 부부가 1주택을 공동으로 소유한 경우 부부공동명의 1주택자로 특례를 신청하여 1세대 1주택자 공제를 적용받을 수 있게 되었습니다. 부부공동명의 1주택자 특례에 대한 자세한 내용과 계산방법은 종합부동산세 계산기의 '주의사항'에서 확인해주시기 바랍니다.
- 일시적 2주택 등 1세대 1주택자 특례 : 2022년 9월 종합부동산세법 개정으로 1주택과 일시적 2주택·상속주택·지방저가주택 등 법정요건을 충족하는 특례주택을 소유한 경우 특례를 신청하여 1세대 1주택자 공제를 적용받을 수 있게 되었습니다. 일시적 2주택 등 1세대 1주택자 특례에 대한 자세한 내용과 계산방법은 종합부동산세 계산기의 '주의사항'에서 확인해주시기 바랍니다.

#### 3) 자산내역 입력 : 자산별 공시가격을 입력하고 조정대상지역 해당 여부를 선택해주세요. 공동명의 자산이라면 해당 자산의 소유지분비율을 함께 입력하시기 바랍니다.

#### 4) 부가정보 입력 : 세부담의 상한 반영을 위한 직전년도 과세정보와 지역자원시설세 계산을 위한 건물분 공시가격을 입력하시면 더 정확한 보유세를 확인하실 수 있습니다.`,

  주의사항: `
## 1세대 1주택 재산세 특례

2021년부터 2023년까지 과세기준일 현재 1세대 1주택에 해당하는 주택으로 공시가격이 9억원 이하인 주택에 대해서는 표준세율(0.1% ~ 0.4%)보다 0.05%P 낮은 특례세율(0.05% ~ 0.35%)이 적용되며, 2022년과 2023년 과세기준일 현재 1세대 1주택에 해당하는 주택에 대해서는 일반 공정시장가액비율(60%)보다 낮은 특례 공정시장가액비율(2022년 45%, 2023년 43% ~ 45%)이 적용됩니다. 1세대 1주택 재산세 특례에 대한 자세한 내용은 재산세 계산기의 '주의사항'을 참고해주시기 바랍니다.

## 재산세 도시지역분과 지역자원시설세

재산세 도시지역분과 지역자원시설세는 재산세와 함께 부과됩니다. 사용자분들의 편의를 위해 기본적으로 재산세 계산에 재산세 도시지역분은 포함되고, 지역자원시설세는 제외되도록 설정되어 있습니다. 재산세 도시지역분과 지역자원시설세에 대한 자세한 내용은 재산세 계산기의 '주의사항'과 '지역자원시설세'를 참고해주시기 바랍니다.

## 종합부동산세 중과세율

2022년 납부분까지는 납세의무자가 3주택 이상 또는 조정대상지역 내 2주택을 소유하고 있는 경우 중과세율이 적용되었으나, 2023년 1월 종합부동산세법 개정으로 2023년 납부분부터는 납세의무자가 3주택 이상을 소유하고 주택분 종합부동산세 과세표준이 12억원을 초과하는 경우에만 중과세율이 적용됩니다.

- 2022년 납부분까지 중과세율 : 1.2% ~ 6%
- 2023년 납부분부터 중과세율 : 0.5% ~ 5%

## 공동명의 자산의 계산

부부공동명의 또는 일부 지분을 소유한 자산을 계산할 때 지분비율을 제외한 나머지 모든 정보는 자산 기준으로 입력하시기 바랍니다. 계산기를 통해 산출된 보유세(재산세와 종합부동산세)는 입력된 지분비율이 반영된 것으로 해당 납세의무자가 납부하여야 하는 금액입니다.

- 예) 공시가격이 10억원인 주택의 80%를 소유한 경우
  - ① 공시가격 10억 입력 : 공시가격 및 직전년도 과세정보(공시가격, 재산세, 도시지역분) 등 지분비율을 제외한 모든 정보를 자산(지분비율 100%)을 기준으로 입력
  - ② 지분비율 80% 입력

## 세대의 보유세 계산

세대의 보유세 총액은 주택을 보유하고 있는 세대원(납세의무자)별로 계산한 보유세를 모두 합산하여 확인할 수 있습니다.

- 예) 남편 단독명의 아파트 A와 부부공동명의 아파트 B를 보유한 세대의 보유세 총액 : ① 남편의 자산내역(단독명의 A와 공동명의 B)으로 계산한 보유세와 ② 아내의 자산내역(공동명의 B)으로 계산한 보유세를 합산

## 부부공동명의 1주택자 특례 계산

부부공동명의 1주택자 특례를 신청하였거나 특례의 적용 효과를 미리 살펴보고 싶은 경우 기본정보에서 1세대 1주택 > 1세대 1주택자를 체크하고 계산하시기 바랍니다. 부부공동명의 1주택자 특례는 대표 납세의무자가 배우자의 지분 또한 모두 소유한 것으로 보아 종합부동산세를 계산하므로 자산내역 추가사항의 공동명의에는 체크를 해제하여 지분비율 입력 없이 계산하시기 바랍니다.

- 예) 부부가 동일 지분비율(50%)로 1주택을 보유한 경우 1주택자 특례 계산: 1세대 1주택 > 1세대 1주택자에 체크하고 부부 중 대표 납세의무자가 지분의 100%를 소유한 것으로 보아 연령 및 보유기간, 공시가격 등 정보 입력

## 일시적 2주택 등 1세대 1주택자 특례 계산

일시적 2주택 등 1세대 1주택자 특례를 신청하였거나 특례의 적용 효과를 미리 살펴보고 싶은 경우 기본정보에서 1세대 다주택 > 1세대 1주택자 + 특례주택을 체크하고 계산하시기 바랍니다. 일시적 2주택 등 1세대 1주택자 특례는 일반주택에 대하여만 연령 및 보유기간 세액공제를 적용하여 종합부동산세를 계산하므로 자산내역 추가사항의 특례주택에 체크가 되어있는 부분에 특례주택의 정보를 입력하여 계산하시기 바랍니다.

- 예) 법정요건을 충족하는 일시적 2주택을 보유한 경우 1세대 1주택자 특례 계산: 1세대 다주택 > 1세대 1주택자 + 특례주택에 체크하고 자산[1]에는 종전주택의 정보를, 그리고 특례주택이 체크되어 있는 자산[2]에는 신규주택의 정보를 입력

## 세부담의 상한

재산세와 종합부동산세에서는 당해 부과되는 세액이 직전 연도에 부과된 세액의 일정 비율을 초과하지 않도록 세부담의 상한을 설정해두고 있습니다. 재산세 부담의 상한과 종합부동산세 부담의 상한에 대한 자세한 내용은 재산세 계산기의 '주의사항'과 종합부동산세 계산기의 '주의사항'을 참고해주시기 바랍니다.

**주택에 부과되는 보유세를 산정하기 위한 참고용 계산기입니다. 실제 부과되는 보유세와 차이가 있을 수 있습니다.**`,
};

export const acquisitionTaxMap = {
  설명: `## 취득세란? 
> 토지, 건물, 주택 등 일정한 재산을 취득하는 경우 납부하는 지방세입니다.
  
## 부동산대책 및 개정세법 반영 안내

2023년 세법개정과 2024년 1월·2월 세법개정이 취득세에 미치는 영향을 모두 계산기에 반영하였습니다. 부동산대책과 개정세법이 다루고 있는 취득세에 대한 자세한 내용은 '세법개정'에서 확인해주시기 바랍니다.
  
## 취득세 과세표준

2021년 12월 지방세법 개정으로 2023년 1월 1일부터 취득세의 과세표준 제도가 과세대상의 실질가치를 반영하도록 변경됩니다. 

취득일자 — 2022년 12월 31일 이전 또는 2023년 1월 1일 이후 — 에 따라 취득세의 과세표준이 달라질 수 있으니 유의하시기 바랍니다.

- **지방세법 제10조, 제10조의2, 제10조의3, 제10조의4 및 지방세법 시행령 제14조**

### 1) 2022년 12월 31일 이전 취득

취득세의 과세표준은 원칙적으로 취득자가 신고한 취득 당시의 가액으로 하며, 신고 또는 신고가액의 표시가 없거나 신고가액이 시가표준액보다 작을 때에는 시가표준액을 과세표준으로 합니다. 단, 「부동산 거래신고 등에 관한 법률」에 따라 신고서를 제출하여 검증이 이루어진 취득가액은 시가표준액보다 작더라도 인정됩니다.

- **매매 등 유상취득 및 원시취득**: MAX(신고가액, 시가표준액)
- **상속·증여 등 무상취득**: 시가표준액

### 2) 2023년 1월 1일 이후 취득

취득세의 과세표준은 취득 당시의 가액으로 하며, 취득방법에 따라 사실상의 취득가격, 시가인정액 또는 시가표준액을 취득 당시의 가액으로 봅니다. 취득세 과세표준 제도가 과세대상의 실질가치를 반영하도록 변경되면서 상속 등 예외적인 경우를 제외하고는 사실상 취득일 현재의 시가를 기준으로 취득세가 과세되니 유의하시기 바랍니다.

- **매매 등 유상취득**: 사실상의 취득가격
  - 특수관계인으로부터 시가인정액보다 3억원 또는 시가인정액의 5% 이상 낮은 가격으로 부동산을 취득한 경우: 시가인정액
- **무상취득 - 증여**:
  - 시가표준액이 1억원 이하인 부동산: 시가인정액과 시가표준액 중 납세자 선택
  - 시가인정액 산정이 어려운 경우: 시가표준액
- **무상취득 - 상속**: 시가표준액
- **원시취득**: 사실상의 취득가격
  - 법인이 아닌 자가 건축물을 건축하여 취득하는 경우로서 사실상의 취득가격 확인이 어려운 경우: 시가표준액

### 3) 시가인정액

평가기간 내 취득재산의 매매·감정·경매·공매 사실이 있다면 취득재산의 매매가액 등을 시가인정액으로 우선 보며, 취득재산의 매매가액 등이 없는 경우에는 유사재산의 매매가액 등을 시가인정액으로 봅니다. 시가인정액으로 볼 수 있는 가액이 둘 이상이면 취득일과 가장 가까운 날의 가액을 시가인정액으로 적용합니다.

- **취득재산의 매매가액 등**: 취득일 전 6개월부터 취득일 후 3개월 이내 매매가액이나 감정가액 또는 경매·공매가액
- **유사재산의 매매가액 등**: 취득일 전 6개월부터 취득일 후 3개월 이내(취득세 과세표준을 신고하였으면 신고일) 해당 재산과 면적·위치·용도 및 시가표준액이 같거나 유사한 다른 재산의 매매가액이나 감정가액 또는 경매·공매가액

### 취득세 신고·납부

취득세 과세물건을 취득하였을 때는 아래의 신고기한 내에 부동산 소재지 관할 시군구청에 취득세를 신고와 함께 납부해야 합니다. 신고기한 내 신고·납부하지 않을 경우 신고불성실 가산세 등이 부과될 수 있습니다.

- **유상·원시취득**: 취득일로부터 60일 이내
- **무상취득 - 증여**:
  - 2022년 12월 31일 이전 취득: 취득일로부터 60일 이내
  - 2023년 1월 1일 이후 취득: 취득일이 속하는 달의 말일로부터 3개월 이내
- **무상취득 - 상속**: 취득일이 속하는 달의 말일로부터 6개월 이내

### 관련 법령

- 지방세법 제2장 취득세

**취득세를 산정하기 위한 참고용 계산기입니다. 실제 신고·납부해야 하는 취득세와 차이가 있을 수 있습니다.**`,

  "매매·교환": `
## 1세대의 범위

취득세에서의 1세대란 주택을 취득하는 사람과 세대별 주민등록표에 함께 기재되어 있는 가족(동거인 제외)으로 구성된 세대를 말합니다. 이때 주택을 취득하는 사람의 배우자 및 취득일 현재 30세 미만인 미혼 자녀는 별도의 세대별 주민등록표에 기재되어 있더라도 같은 세대를 구성하는 것으로 봅니다. 다만 아래의 예외에 해당하는 경우에는 부모와 자녀가 각각 별도의 세대로 인정됩니다. - 지방세법 시행령 제28조의3

- 별도의 세대별 주민등록표에 기재된 30세 미만의 자녀(미성년자 제외)로 주택 취득일이 속하는 달의 직전 12개월 동안 발생한 소득이 기준중위소득을 12개월로 환산한 금액의 40% 이상이고, 소유 주택을 관리·유지하면서 독립된 생계를 유지할 수 있는 경우
- 취득일 현재 65세 이상의 직계존속(배우자의 직계존속 포함, 직계존속 중 어느 한 사람이 65세 미만인 경우 포함)을 동거봉양하기 위하여 30세 이상의 직계비속, 혼인한 직계비속 또는 소득요건을 충족하는 성년인 직계비속이 합가한 경우

## 주택 수 산정 방법

신규주택 취득 시 적용되는 취득세율은 신규취득으로 보유하게 되는 주택 수와 신규취득 주택의 조정대상지역 여부에 따라 결정됩니다. 이때 신규취득으로 보유하게 되는 주택의 수는 신규주택의 취득일 현재 취득하는 주택을 포함하여 1세대가 국내에 소유하는 주택의 수로 하며 조합원입주권, 분양권 및 주거용 오피스텔도 주택 수 산정에 포함됩니다. - 지방세법 시행령 제28조의4

#### 1) 조합원입주권, 분양권 및 주거용 오피스텔은 2020년 8월 12일 이후 취득분부터 주택 수 산정에 포함되며, 2020년 8월 11일 이전에 매매계약(또는 분양계약)을 한 경우에는 제외됩니다.

#### 2) 조합원입주권 또는 분양권에 의하여 취득하는 주택은 입주권 또는 분양권의 취득일을 기준으로 해당 주택 취득 시의 세대별 주택 수를 산정합니다.

#### 3) 법정요건을 충족하는 아래의 주택 등은 주택 수 산정에서 제외됩니다.

- 중과 제외대상 주택 중 시가표준액이 1억원 이하인 주택 등
- 시가표준액이 1억원 이하인 오피스텔
- 상속을 원인으로 취득하여 상속개시일로부터 5년이 지나지 않은 주택, 조합원입주권, 주택분양권 또는 오피스텔
- 혼인한 사람이 혼인 전부터 소유하던 주택분양권으로 주택을 취득하는 경우 다른 배우자가 혼인 전부터 소유하던 주택

**취득세 계산 시 취득하는 주택을 포함하여 보유하게 되는 주택의 수를 대상주택으로 선택하여 계산하시기 바랍니다. 예를 들어 현재 2주택을 보유한 세대가 새로 주택을 취득하는 경우라면 대상주택으로 3주택(기존보유 2주택 + 신규취득 1주택)을 선택해야 합니다.**

## 일시적 1세대 2주택

국내에 주택, 조합원입주권, 분양권 또는 오피스텔을 1개 소유한 세대가 신규주택을 취득하여 일시적으로 2주택이 된 경우에도 종전주택 등을 신규주택을 취득한 날로부터 3년 이내 처분한다면 신규주택 취득에 대하여 일반 취득세율(주택가액에 따라 1% ~ 3%)이 적용됩니다. - 지방세법 시행령 제28조의5

- 조합원입주권 또는 분양권을 1개 소유한 세대가 신규주택을 취득한 경우에는 해당 조합원입주권 또는 분양권에 의한 주택을 취득한 날부터 중복보유기간(종전주택 처분기한)을 기산합니다.
- 일시적 1세대 2주택에 해당하는 주택을 취득하는 경우에는 먼저 일반 취득세율(주택가액에 따라 1% ~ 3%)로 취득세를 신고·납부한 후 중복보유기간 내 종전주택 등을 처분하면 됩니다. 중복보유기간 내 종전주택 등의 처분이 이루어지지 않았을 때는 추징금과 함께 가산세 등이 부과될 수 있으니 유의하시기 바랍니다.
- 만약 신규주택 취득 당시 종전주택 등과 신규주택이 모두 조정대상지역에 있다면 종전주택 등의 처분일과 신규주택의 취득일에 따라 아래의 중복보유기간이 적용됩니다. 2023년 2월 지방세법 시행령 개정으로 종전주택 등을 2023년 1월 12일 이후 처분하는 경우에는 종전주택 등과 신규주택의 조정대상지역 여부와 관계없이 3년의 중복보유기간이 적용되니 유의하시기 바랍니다.

#### 1) 종전주택 등을 2023년 1월 11일 이전에 처분하는 경우 : 종전주택 등의 처분일과 신규주택의 취득일에 따라 1년 ~ 2년의 중복보유기간이 적용됩니다.
- **2022년 5월 9일 이전 신규주택 취득**
  - ① 종전주택 등을 2022년 5월 9일 이전 처분 : 1년
  - ② 종전주택 등을 2022년 5월 10일 이후 처분 : 2년
- **2022년 5월 10일 이후 신규주택 취득 : 2년**

#### 2) 종전주택 등을 2023년 1월 12일 이후에 처분하는 경우 : 종전주택 등과 신규주택의 조정대상지역 여부와 관계없이 3년의 중복보유기간이 적용됩니다.

법정요건을 충족하는 일시적 1세대 2주택을 취득하는 경우 취득세 계산 시 대상주택을 2주택이 아닌 1주택으로 선택하여 계산하시기 바랍니다.

## 중과 제외대상 주택

공공성이 높은 주택이나 저가주택 또는 농어촌주택 등 투기목적으로 취득하는 것이라고 보기 어려운 주택은 중과대상에서 제외되어 일반 취득세율(주택가액에 따라 1% ~ 3%)이 적용됩니다. - 지방세법 시행령 제28조의2

- 저가주택 : 시가표준액이 1억원 이하인 주택으로 「도시 및 주거환경정비법」에 따른 정비구역이나 「빈집 및 소규모주택 정비에 관한 특례법」에 따른 사업시행구역에 소재하지 않은 주택

법정요건을 충족하여 중과에서 제외되는 주택을 취득하는 경우 취득세 계산 시 대상주택을 중과 제외대상 주택으로 선택하여 계산하시기 바랍니다.`,

  "증여·상속": `
## 증여에 대한 취득세 증과

2020년 7월 10일 발표된 부동산대책과 이에 대한 후속조치인 2020년 8월 세법개정으로 2020년 8월 12일 이후 증여로 무상취득하는 부동산이 조정대상지역 내 시가표준액(일부 지분을 취득하는 경우에는 전체 주택의 시가표준액) 3억원 이상의 주택이라면 중과 증여취득세율(12%)이 적용됩니다. 그 외 기타 부동산의 증여 취득에 대해서는 일반 증여취득세율(3.5%)이 적용됩니다. - 지방세법 제13조의2제2항 및 지방세법 시행령 제28조의6제1항

- 조정대상지역 내 시가표준액 3억원 이상 주택 증여 취득 : 중과 증여취득세율 12%
- 비조정대상지역 또는 조정대상지역 내 시가표준액 3억원 미만 주택 증여 취득 : 일반 증여취득세율 3.5%
- 그 외 기타 부동산 증여 취득 : 일반 증여취득세율 3.5%

#### 1) 조정대상지역 내 시가표준액 3억원 이상인 주택이더라도 1세대 1주택자가 소유한 주택을 배우자나 직계존비속이 증여로 취득하는 경우라면 예외적으로 중과 증여취득세율이 아닌 일반 증여취득세율이 적용됩니다. 해당 요건을 충족하는 경우 취득세 계산 시 1세대 1주택 중과 제외를 체크하고 계산하시기 바랍니다.

#### 2) 주택의 일부 지분을 증여로 취득하는 경우에는 전체 주택의 시가표준액을 기준으로 중과 취득세율 적용 여부를 판단합니다. 이에 해당하는 경우 취득세 계산 시 지분취득을 체크하고 전체 주택의 시가표준액을 함께 입력하여 계산하시기 바랍니다.

## 무주택 세대 상속 특례

주택을 상속으로 취득하는 경우에는 2.8%의 상속취득세율이 적용됩니다. 단, 상속인과 상속인 세대의 구성원 모두가 무주택자인 무주택 세대가 상속으로 주택을 취득하여 1세대 1주택이 되는 경우에는 0.8%의 특례 상속취득세율이 적용됩니다. 주택을 여러 사람이 공동으로 상속받는 경우에는 지분이 가장 큰 상속인이 상속주택을 소유한 것으로 보아 취득세를 산정하며, 만약 지분이 가장 큰 상속인이 두 명 이상일 때에는 아래의 순서에 따라 상속주택의 소유자를 판정하게 됩니다. - 지방세법 제15조

- 상속주택에 거주하는 사람
- 나이가 가장 많은 사람

지방세법에 따른 상속주택의 소유자를 기준으로 무주택 세대 상속 특례의 요건을 충족하는 경우 취득세 계산 시 무주택 세대 상속 특례를 체크하고 계산하시기 바랍니다.

## 자경농민 농지 상속 특례

농지를 상속으로 취득하는 경우에는 2.3%의 상속취득세율이 적용됩니다. 단, 자경농민이 「지방세특례제한법」 제6조제1항에 따라 취득세의 감면대상이 되는 농지를 상속을 취득하는 경우에는 0.3%의 특례 상속취득세율이 적용되며, 취득세의 50%를 감면받을 수 있어 결과적으로는 0.15%의 취득세율이 적용됩니다. 자경농민의 요건 등 자경농민의 농지 취득세 감면에 대한 자세한 내용은 '감면혜택 - 자경농민·귀농인의 농지에 대한 취득세 감면'을 참고해주시기 바랍니다. - 지방세법 제15조 및 지방세특례제한법 제6조`,

  감면혜택: `
## 생애최초 주택 구입에 대한 취득세 감면

생애 최초로 취득가액이 12억원 이하인 주택을 유상거래(부담부증여 제외)로 취득하면서 법정요건을 모두 충족하는 경우 2025년 12월 31일까지 취득세를 최대 200만원까지 감면받을 수 있습니다. 2023년 3월 지방세특례제한법 개정으로 2022년 6월 21일 이후 취득분부터 취득가액 요건이 완화되었고, 합산소득 요건은 폐지되었으니 유의하시기 바랍니다. - 지방세특례제한법 제36조의3

- 취득세액이 200만원 이하 : 취득세 면제
- 취득세액이 200만원 초과 : 최대 200만원 감면

① 주택의 취득일까지 본인 및 배우자가 주택을 소유한 사실이 없어야 하며, 주택의 취득일로부터 3개월 이내 전입신고와 함께 거주를 시작해야 합니다. 단, 2023년 5월 지방세특례제한법 시행령 개정으로 잔여 임대차 계약기간이 1년 이내인 주택을 2023년 5월 16일 이후 취득한 경우에는 3개월 이내 거주를 시작하지 않더라도 취득세를 감면받을 수 있으니 유의하시기 바랍니다.

② 주택의 취득일로부터 3개월 동안 1세대 1주택을 유지해야 하며, 상시거주한 기간이 3년 이상이 되기 전에 해당 주택을 매각·증여(배우자에게 지분을 매각·증여하는 경우는 제외)하거나 다른 용도(임대 포함)로 사용하지 않아야 합니다.

법정요건을 충족하여 생애최초 주택 구입에 해당하는 경우 취득세 계산 시 대상주택을 생애최초 구입 주택으로 선택하여 계산하시기 바랍니다.

## 출산·양육을 위한 주택 구입에 대한 취득세 감면

자녀를 출산한 부모가 자녀와 상시 거주할 목적으로 출산일 전 1년 이내 또는 출산일 후 5년 이내에 취득가액이 12억원 이하인 1주택을 취득하는 경우 2025년 12월 31일까지 취득세를 최대 500만원까지 감면받을 수 있습니다. - 지방세특례제한법 제36조의5

- 취득세액이 500만원 이하 : 취득세 면제
- 취득세액이 500만원 초과 : 최대 500만원 감면

① 자녀의 출생 사살이 가족관계등록부에서 확인될 수 있어야 하며, 취득하는 주택이 취득 당시 1세대 1주택에 해당하거나 주택을 취득한 날부터 3개월 이내에 1세대 1주택에 해당해야 합니다.

② 주택의 취득일(출산일 전 취득한 경우에는 출산일)로부터 3개월 이내 전입신고와 함께 거주를 시작해야 합니다. 해당 자녀와 상시거주한 기간이 3년 이상이 되기 전에 해당 주택을 매각·증여(배우자에게 지분을 매각·증여하는 경우는 제외)하거나 다른 용도(임대 포함)로 사용하지 않아야 합니다.

법정요건을 충족하여 출산·양육을 위한 주택 취득에 해당하는 경우 취득세 계산 시 대상주택을 출산·양육 주택으로 선택하여 계산하시기 바랍니다.

## 상시거주 목적의 서민주택에 대한 취득세 감면

상시거주를 목적으로 취득가액이 1억원 미만인 전용면적 40제곱미터 이하의 주택을 취득(상속·증여로 인한 취득 및 원시취득 제외)하여 1세대 1주택이 되는 경우 2024년 12월 31일까지 취득세를 면제받을 수 있습니다. - 지방세특례제한법 제33조제2항

① 종전주택을 보유하고 있지만 신규주택을 취득한 날부터 60일 이내 종전주택을 증여 외의 사유로 매각하여 1세대 1주택이 되는 경우를 포함합니다.

② 주택의 취득일로부터 3개월 이내 전입신고와 함께 거주를 시작해야 하며 2년 동안 상시거주해야 합니다.

③ 상시거주한 기간이 2년 이상이 되기 전에 해당 주택을 매각·증여하거나 다른 용도(임대 포함)로 사용하지 않아야 합니다.

## 주택임대사업자의 최초분양 임대주택에 대한 취득세 감면

주택임대사업자가 임대를 목적으로 취득가액이 3억원(수도권 6억원) 이하인 공동주택 또는 오피스텔을 건축주로부터 최초로 분양받는 경우 2024년 12월 31일까지 취득세를 면제받거나 50% 감면받을 수 있습니다. - 지방세특례제한법 제31조제2항

전용면적 60제곱미터 이하 : 취득세 면제(단, 취득세액이 200만원을 초과하면 85% 감면)

장기임대주택을 20호 이상 취득 또는 20호 이상의 장기임대주택을 보유한 자가 추가로 장기임대주택을 취득하는 경우 : 취득세 50% 감면

단, 임대의무기간 동안 해당 임대주택을 매각·증여하거나 임대 외의 용도로 사용하는 경우 또는 임대료 연 5% 증액제한 미준수 등으로 임대사업자 등록이 말소되는 경우 등에는 감면받은 취득세를 추징당할 수 있으니 유의하시기 바랍니다.

## 자경농민·귀농인의 농지에 대한 취득세 감면

자경농민 또는 귀농인이 직접 경작할 목적의 농지, 관계 법령에 따라 농지를 조성하기 위한 임야, 농업용 시설 등을 취득하는 경우에는 2026년 12월 31일(귀농인은 2024년 12월 31일)까지 취득세를 50% 감면받을 수 있습니다. - 지방세특례제한법 제6조

- 자경농민 : 농업을 주업으로 하는 사람으로 2년 이상 영농에 종사하는 등 법정요건을 갖춘 사람 또는 후계청년농어업인법에 따른 후계농업경영인 및 청년창업형
- 귀농인 : 귀농일 1년 이전부터 농촌 외의 지역에서 계속하여 실제 거주하며 1년 이상 농업에 종사하지 않다가 농촌지역으로 이주하여 실제 거주하는 법정요건을 갖춘 사람 - 지방세특례제한법 시행령 제3조제5항 및 제6항

단, 농지의 취득일로부터 2년 이내 직접 경작하지 않거나 법정 기간 이내 매각·증여하거나 다른 용도로 사용하는 경우 또는 귀농인이 귀농일로부터 3년 이내 농업 외의 산업에 종사하는 경우 등에는 감면받은 취득세를 추징당할 수 있으니 유의하시기 바랍니다.
`,

  세법개정: `
## 개정내용 적용시기

지방세법 및 지방세법 시행령의 개정사항은 시행일자 이후 취득분부터 적용되는 내용과 시행일자 이후 처분하는 경우부터 적용되는 내용 등으로 구분되어 있으니 유의하시기 바랍니다.

## 출산·양육을 위한 주택 취득에 대한 취득세 감면 신설[2024년 1월 1일 취득분부터 적용]

자녀를 출산한 부모가 해당 자녀와 함께 거주하기 위해 취득가액이 12억원 이하인 1주택을 취득하는 경우 2025년 12월 31일까지 취득세를 최대 500만원까지 감면받을 수 있는 제도가 신설되었습니다.

## 주택 수 산정에서 제외되는 예외 추가[2023년 3월 14일 취득분부터 적용]

취득세율 결정을 위한 주택 수 산정에서 ① 혼인한 사람이 혼인 전부터 소유하던 주택분양권으로 주택을 취득하는 경우 다른 배우자가 혼인 전부터 소유하던 주택과 ② 주택 수 산정일 현재 시가표준액이 1억원 이하인 부속토지(부속토지만을 소유한 경우)를 주택 수에서 제외하도록 예외가 추가되었습니다.

## 일시적 1세대 2주택 중과배제 요건 완화[2023년 1월 12일 이후 종전주택 등을 처분하는 경우부터 적용]

종전주택 등과 신규주택 모두 조정대상지역인 경우의 일시적 1세대 2주택 중과배제 요건이 기존 2년에서 3년으로 완화되었습니다. 종전주택 등을 2023년 1월 12일 이후 처분하는 경우에는 종전주택 등과 신규주택의 조정대상지역 여부와 관계없이 3년의 중복보유기간(종전주택 처분기한)이 적용됩니다.

## 취득세 과세표준 제도 변경[2023년 1월 1일 취득분부터 적용]

2021년 12월 지방세법 개정으로 취득세 과세표준 제도가 과세대상의 실질가치를 반영하도록 변경되었습니다. 2023년 1월 1일 이후 취득분부터는 ① 부동산 등을 유상·원시취득하는 경우에는 사실상의 취득가격을, 그리고 ② 증여로 무상취득하는 경우에는 시가인정액(취득일로부터 6개월 이내 매매·감정공매가액 중 가장 최근 거래 가액 또는 유사매매사례가액)을 과세표준으로 하게 되니 유의하시기 바랍니다.

#### 1) 2022년 12월 31일 이전 취득분까지
- 유상·원시취득 : MAX(신고가액, 시가표준액)
- 무상취득 : 시가표준액(증여 또는 상속)

#### 2) 2023년 1월 1일 이후 취득분부터
- 유상·원시취득 : 사실상의 취득가격
- 무상취득 : 시가인정액(증여), 시가표준액(상속)

## 증여 등 무상취득 시 신고·납부 기한 연장[2023년 1월 1일 취득분부터 적용]

2021년 12월 지방세법 개정으로 무상취득의 과세표준이 시가인정액으로 변경됨에 따라 시가 산정에 걸리는 기간과 상증세법의 증여세 신고기한 등으로 고려하여 상속을 제외한 증여 등 무상취득 시 취득세 신고·납부 기한이 기존 취득일로부터 60일 이내에서 취득일이 속하는 달의 말일로부터 3개월 이내로 연장됩니다. 연장된 신고·납부 기한은 변경된 과세표준 제도가 시행되는 2023년 1월 1일 이후 취득분부터 적용되니 유의하시기 바랍니다.

## 일시적 1세대 2주택 중과배제 요건 완화[2022년 5월 10일 취득분부터 적용]

종전주택 등과 신규주택 모두 조정대상지역인 경우의 일시적 1세대 2주택 중과배제 요건이 완화되었습니다. 2022년 5월 10일 이후 신규주택을 취득한 경우에는 기존의 1년보다 완화된 2년의 중복보유기간(종전주택 처분기한)이 적용됩니다. 또한 부칙에 따라 2022년 5월 9일 이전에 신규주택을 취득하였더라도 2022년 5월 10일 이후 종전주택 등을 처분하는 경우에는 완화된 2년의 중복보유기간이 적용되니 유의하시기 바랍니다.

## 경과규정

#### 1) 2020년 8월 경과규정 : 2020년 8월 지방세법 개정으로 변경된 취득세율(다주택자 취득세율 중과, 8% 또는 12%)은 2020년 8월 12일 이후 취득분부터 적용됩니다. 단, 2020년 7월 10일 부동산대책 발표 이전에 매매계약(공동주택 분양계약 포함)을 체결한 주택은 2020년 8월 12일 이후 취득하더라도 종전의 취득세율이 적용됩니다.
- 1세대 3주택 이하에 해당하는 주택 : 주택가액에 따라 1%~ 3%
- 1세대 4주택 이상에 해당하는 주택 : 4%`,
};

export const brokerageMap = {
  설명: `## 중개수수료(중개보수 또는 복비) 계산기
  
> 매매, 전세, 월세 등 거래유형과 주택, 오피스텔, 토지, 상가 등 거래대상에 따른 중개수수료(중개보수 또는 복비)의 계산을 도와주는 계산기입니다.

## 중개보수 개정 반영 안내

2021년 8월 20일 발표된 중개보수 및 중개서비스 개선 방안과 이에 대한 후속조치인 2021년 10월 공인중개사법 시행규칙 개정이 중개수수료에 미치는 영향을 반영하였습니다. 개정된 중개보수 상한요율은 공인중개사법 시행규칙이 공포·시행되는 2021년 10월 19일 이후 중개의뢰인 간 계약에 적용되니 유의하시기 바랍니다.

#### 1) **주택 매매 거래 보수요율**

- 6억원 이상 ~ 9억원 미만 구간 요율 인하: 0.5% → 0.4%
- 9억원 이상의 구간 요율 단계적 인하: 0.9% → 0.5% ~ 0.7%

#### 2) **주택 임대차 거래 보수요율**

- 3억원 이상 ~ 6억원 미만 구간 요율 인하: 0.4% → 0.3%
- 6억원 이상의 구간 요율 단계적 인하: 0.8% → 0.4% ~ 0.6%

## 중개수수료 또는 중개보수란?

중개수수료 또는 중개보수는 공인중개사를 통해 부동산의 매매계약이나 전세 및 월세 임대차 계약 등을 체결하는 경우 중개의뢰인이 공인중개사의 중개행위에 대하여 지급하는 대가를 말합니다. 지난 2014년 공인중개사법 개정과 함께 중개수수료에서 중개보수로 용어가 변경되었지만 아직은 중개수수료라는 용어가 더 널리 사용되고 있습니다.

## 중개수수료의 지급과 지급시기

일반적인 계약에서는 계약 당사자인 중개의뢰인 — 매매 계약이면 매도인과 매수인, 임대차 계약이면 임대인과 임차인 — 모두가 공인중개사에게 중개수수료를 지급하여야 합니다. 중개수수료는 보통 거래대금의 지급이 완료되는 잔금일에 지급하지만 중개의뢰인과 공인중개사가 약정으로 지급시기를 달리 정할 수 있습니다. - 공인중개사법 제32조

## 중개수수료의 상한요율과 한도액

주택 중개수수료의 상한요율과 한도액은 공인중개사법 시행규칙에서 정한 범위 내에서 특별시·광역시·도 또는 특별자치도가 각 지역의 상황에 맞게 달리 정할 수 있습니다. 현재는 전국의 상한요율과 한도액이 같지만 향후 시·도별 조례가 개정되는 경우에는 지역별 차이가 발생할 수 있습니다. 반면 오피스텔 및 토지, 상가 등 기타 부동산 중개수수료의 상한요율은 공인중개사법 시행규칙에서 일률적으로 정하고 있어 주택의 경우와 달리 지역별 차이가 없습니다. - 공인중개사법 시행규칙 제20조

## 중개수수료의 결정

중개수수료는 공인중개사법 시행규칙과 특별시·광역시·도 또는 특별자치도가 조례에서 정하고 있는 상한요율과 한도액에 따라 결정되는 최대 중개수수료 내에서 중개의뢰인과 공인중개사가 협의하여 결정하게 됩니다.

공인중개사는 자신의 사업 규모에 따라 일반과세자면 중개수수료의 10%를, 그리고 간이과세자면 중개수수료의 4%를 부가가치세로 하여 중개의뢰인에게 청구할 수 있습니다.

공인중개사는 중개대상물의 권리관계 등의 확인 또는 반환채무이행 보장에 소요되는 실비를 중개수수료와 함께 청구할 수 있습니다.
`,

  주의사항: `
## **오피스텔의 구분**

공인중개사법 시행규칙에서는 오피스텔을 주거시설의 유무와 전용면적의 크기에 따라 주거용 오피스텔과 그 외 기타 오피스텔로 구분하고 있습니다. 아래의 2가지 요건을 모두 충족하는 오피스텔은 공부상의 용도나 실제 사용 용도와 관계없이 주거용 오피스텔로 보며 그 외 기타 오피스텔과 다른 상한요율이 적용됩니다. - 공인중개사법 시행규칙 제20조

#### 1) 주거용 오피스텔의 요건

- 상·하수도 시설이 갖추어진 전용입식 부엌, 전용수세식 화장실 및 목욕시설을 갖출 것
- 전용면적 85제곱미터 이하

#### 2) 오피스텔의 상한요율

- 주거용 오피스텔: 매매 0.5%, 임대차 0.4%
- 그 외 기타 오피스텔: 매매 및 임대차 0.9%

## **상가주택의 구분**

상가주택은 전체 면적 중 주택 부분 면적의 비율에 따라 중개대상물 전체를 주택 또는 기타 부동산으로 구분합니다. 건축물의 전체 면적 중에서 주택 부분의 면적이 차지하는 비율이 50% 이상이라면 중개대상물 전체를 주택으로 보아 주택 중개수수료의 상한요율과 한도액이 적용됩니다.

- 주택 부분 면적이 50% 이상: 주택
- 주택 부분 면적이 50% 미만: 기타 부동산

## **월세 임대차 계약의 거래금액**

월세 임대차 계약의 거래금액은 보증금에 월 단위 차임액에 100을 곱한 금액을 합하여 산정합니다. 다만 이렇게 산정한 금액이 5천만원 미만인 경우에는 보증금에 월 단위 차임액에 70을 곱한 금액을 거래금액으로 합니다.

- 월세 거래금액 = 보증금 + (월세 X 100 또는 70)

## **분양권 매매 계약의 거래금액**

분양권 매매 계약의 거래금액은 거래 당시까지 납입한 계약금과 중도금(대출 포함)에 프리미엄을 모두 합하여 산정합니다.

- 예) 분양가 3억원인 아파트 분양권의 거래금액
  - 계약금 3,000만원 납입
  - 중도금 4,000만원 납입
  - 프리미엄 1,500만원
  - 분양권 거래금액: 8,500만원 (= 계약금 3,000만원 + 중도금 4,000만원 + 프리미엄 1,500만원)

**주의**: 중개수수료를 산정하기 위한 참고용 계산기입니다. 실제로 지급해야 하는 중개수수료와 차이가 있을 수 있습니다.
`,
};

export const capitalGainsTaxMap = {
  설명: `## 양도소득세란? 
> 주택, 건물이나 토지 등 재산의 소유권 양도에 따라 발생하는 양도소득에 대해 부과되는 국세입니다.

## 부동산대책 및 개정세법 반영 안내

2023년 세법개정과 2024년 1월·2월 세법개정이 양도소득세에 미치는 영향을 모두 계산기에 반영하였습니다. 부동산대책과 개정세법이 다루고 있는 양도소득세에 대한 자세한 내용은 '세법개정'에서 확인해주시기 바랍니다.

양도소득세는 납세의무자가 신고·납부하는 세금입니다. 정확한 신고가 이루어지지 않을 경우 가산세 등의 불이익을 받을 수 있으니 실제 세금 신고·납부 시에는 세무사 등 전문가의 상담을 받으시기 바랍니다.

## 양도소득세 계산 순서

#### 1) 양도소득금액 산출 : 양도가액 - 취득가액 - 필요경비 - 장기보유특별공제액

#### 2) 양도소득 과세표준 산출 : 양도소득금액 - 기본공제

#### 3) 양도소득 산출세액 계산 : 양도소득 과세표준 X 세율

## 양도소득세 예정신고·납부

자산의 양도일이 속하는 달의 말일부터 2개월 이내 예정신고와 함께 예정신고세액을 납부해야 합니다. 예정신고 기한 내 신고·납부하지 않을 경우 신고불성실 가산세 등이 부과될 수 있습니다.

## 양도소득세 확정신고·납부

해당 연도에 2건 이상의 부동산을 양도하였다면 그다음 해 5월 확정신고와 함께 확정신고세액을 납부해야 합니다. 만약 해당 연도에 1건의 부동산만을 양도하고 예정신고를 하였다면 별도로 확정신고를 하지 않아도 됩니다.

## 규제지역 현황 및 관련 법령

#### 1) 투기과열지구 현황

| 지역 | 투기과열지구           |
|------|---------------------------|
| 서울 | 강남구, 서초구, 송파구, 용산구 |

2023년 1월 5일 투기과열지구 지정 해제
① 강남구·서초구·송파구·용산구를 제외한 서울 전지역
② 경기 과천, 광명, 성남시 분당구·수정구, 하남
국토교통부공고 제2023-1호

#### 2) 조정대상지역 현황

| 지역 | 조정대상지역           |
|------|---------------------------|
| 서울 | 강남구, 서초구, 송파구, 용산구 |

### 2023년 1월 5일 조정대상지역 지정 해제
① 강남구·서초구·송파구·용산구를 제외한 서울 전지역
② 경기 과천, 광명, 성남시 분당구·수정구, 하남
국토교통부공고 제2023-2호

**본 가이드는 사용자의 이해를 돕기 위해 제시되지 않은 가정을 포함하고 있을 수 있으며, 구체적인 사실 및 정황에 따라 법률적 해석에 이견이 있을 수 있습니다. 또한 제작 및 편집 과정에서 의도하지 않은 오류가 있을 수 있습니다. 가이드의 내용은 반드시 참고 용도로만 활용하시길 바라며, 정확한 내용은 전문가와의 상담을 통해 확인하시기 바랍니다.**
`,
  주의사항: `
## 1세대 1주택의 범위

1세대가 양도일 현재 1주택을 보유하고 있고 보유기간이 2년 이상(2017년 8월 3일 이후 취득한 주택이 취득 당시 조정대상지역에 해당한다면 거주기간도 2년 이상)인 경우 해당 주택의 양도로 발생한 양도차익에 대해서는 양도소득세를 과세하지 않습니다. 1세대 1주택의 요건을 모두 충족하더라도 해당 주택의 양도가액이 12억원(2021년 12월 7일 이전 양도분은 9억원)을 초과하는 고가주택이면 전체 양도차익 중 12억원(2021년 12월 7일 이전 양도분은 9억원) 초과 부분에 대해서 양도소득세를 과세합니다. - 소득세법 시행령 제154조

#### 1) 1년 이상 거주한 주택을 기획재정부령에서 정하는 취학, 근무상의 형편과 같은 부득이한 사유로 양도하는 경우처럼 보유기간 및 거주기간의 제한을 받지 않는 「소득세법 시행령」 제154조 제1항 제1호부터 제3호까지의 예외에 해당하면 양도소득세 계산 시 대상주택을 1주택 보유·거주요건 예외로 선택하여 계산하시기 바랍니다.

#### 2) 조정대상지역 공고일 이후에 주택(분양권 및 조합원입주권으로 취득한 주택 포함)을 취득하였어도 조정대상지역의 공고가 있기 전(또는 조정대상지역 내 거주기간 요건이 신설되기 전인 2017년 8월 2일 이전)에 매매계약을 체결하고 계약금을 지급하였으며, 계약금 지급일을 기준으로 해당 거주자가 속한 세대가 주택을 보유하고 있지 않다면 거주기간 요건이 적용되지 않습니다. 해당 요건을 충족하는 경우 양도소득세 계산 시 조정대상지역의 체크를 해제하고 계산하시기 바랍니다.

## 일시적 1세대 2주택

1주택을 보유한 세대가 그 주택을 양도하기 전 신규주택을 취득(자가건설 포함)하여 일시적으로 2주택이 된 경우에도 종전주택을 취득한 날로부터 1년 이상이 지난 후 신규주택을 취득하였고, 종전주택을 신규주택을 취득한 날로부터 3년 이내 양도한다면 양도하는 종전주택에 대하여 1세대 1주택 비과세 특례가 적용될 수 있습니다. - 소득세법 시행령 제155조

만약 신규주택 취득 당시 종전주택과 신규주택이 모두 조정대상지역에 있다면 종전주택의 양도일과 신규주택의 취득일에 따라 아래의 중복보유기간(종전주택 양도기한)이 적용됩니다. 2023년 2월 소득세법 시행령 개정으로 종전주택을 2023년 1월 12일 이후 양도하는 경우에는 종전주택과 신규주택의 조정대상지역 여부와 관계없이 3년의 중복보유기간이 적용되니 유의하시기 바랍니다.

#### 1) 종전주택을 2023년 1월 11일 이전에 양도하는 경우 : 신규주택의 취득일에 따라 1년 ~ 3년의 중복보유기간이 적용되며, 신규주택으로의 전입 요건이 추가될 수 있습니다.

2018년 9월 13일 이전 : 3년
2018년 9월 14일 이후 ~ 2019년 12월 16일 이전 : 2년
2019년 12월 17일 이후

① 종전주택을 2022년 5월 9일 이전 양도 : 1년 + 1년 이내 신규주택 전입
② 종전주택을 2022년 5월 10일 이후 양도 : 2년

2) 종전주택을 2023년 1월 12일 이후에 양도하는 경우 : 종전주택과 신규주택의 조정대상지역 여부와 관계없이 3년의 중복보유기간이 적용됩니다.

상속, 동거봉양이나 혼인 등으로 일시적 2주택이 된 경우에 대해서는 「소득세법 시행령」 제155조를 참고해주시기 바랍니다. 법정요건을 충족하는 일시적 1세대 2주택을 양도하는 경우 양도소득세 계산 시 대상주택을 2주택이 아닌 1주택으로 선택하여 계산하시기 바랍니다.

## 장기보유특별공제

보유기간이 3년 이상인 자산을 양도하는 경우 장기보유특별공제가 적용됩니다. 양도하는 자산이 1세대 1주택에 해당하는 주택으로 보유기간이 3년 이상이고 보유기간 중 거주기간이 2년 이상이라면 일반 장기보유특별공제보다 공제율이 더 높은 1세대 1주택 장기보유특별공제가 적용됩니다. - 소득세법 제95조 및 소득세법 시행령 제159조의4

#### 일반 장기보유특별공제 : 공제율 6% ~ 30%, 보유기간에 따라 연 2%

#### 1세대 1주택 장기보유특별공제 : 공제율 20% ~ 80%, 보유기간 및 거주기간에 따라 각각 연 4%

**1세대 1주택에 해당하는 주택이더라도 보유기간 중 거주기간이 2년 미만이면 1세대 1주택 장기보유특별공제가 아닌 일반 장기보유특별공제가 적용되니 유의하시기 바랍니다. 1세대 1주택 장기보유특별공제는 양도소득세 계산 시 선택한 대상주택과 입력한 보유정보와 거주정보에 따라 자동으로 반영됩니다.**

## 조정대상지역 양도소득세 중과 및 장기보유특별공제 배제

다주택 세대가 조정대상지역에 있는 주택을 양도하는 경우 기본세율에 20% ~ 30% 포인트가 가산된 중과세율이 적용되고, 장기보유특별공제는 적용되지 않습니다. 2020년 소득세법 개정으로 2021년 6월 1일 이후 양도분부터 중과세율이 인상되었으니 유의하시기 바랍니다. - 소득세법 제95조제2항 및 소득세법 제104조제7항

#### 조정대상지역에 있는 주택으로 1세대 2주택에 해당하는 주택 : 20%P 중과

#### 조정대상지역에 있는 주택으로 1세대 3주택 이상에 해당하는 주택 : 30%P 중과

#### 1) 다주택 세대가 보유기간이 2년 이상인 조정대상지역 내 주택을 2022년 5월 10일부터 2025년 5월 9일 사이에 양도하는 경우에는 양도소득세 중과되지 않으며, 장기보유특별공제도 적용됩니다. 다주택자 양도소득세 중과 한시적 배제 효과는 양도소득세 계산 시 선택한 대상주택과 입력한 양도일자에 따라 자동으로 반영됩니다.

#### 2) 수도권 및 광역시·특별자치시 외의 지역에 소재하는 주택으로 양도 당시 기준시가가 3억원 이하인 주택처럼 「소득세법 시행령」 제167조의3 및 「소득세법 시행령」 제167조의10 등에 따라 중과세율 적용대상에서 제외되는 주택을 양도하는 경우에는 양도소득세가 중과되지 않으며, 장기보유특별공제도 적용됩니다. 위 예외에 해당하면 양도소득세 계산 시 대상주택을 중과 제외대상 주택으로 선택하여 계산하시기 바랍니다.

## 다주택에서 전환된 1세대 1주택

2021년 1월 1일 이후 2주택 이상을 보유한 세대(일시적 1세대 1주택의 특례를 적용받는 경우 제외)가 다른 모든 주택을 처분(양도, 증여 및 용도변경)하고 남은 1주택을 2022년 5월 9일 이전에 양도하는 경우에는 최종적으로 1주택을 보유하게 된 날부터 보유기간과 거주기간을 다시 기산하여 1세대 1주택 비과세 여부를 판단합니다.

2022년 소득세법 시행령 개정으로 2022년 5월 10일 양도분부터는 1세대 1주택 비과세 보유·거주기간 재기산 제도가 적용되지 않습니다. 따라서 2022년 5월 10일 이후 양도분부터는 다주택을 처분하고 남은 최종 1주택을 양도하는 경우더라도 주택의 취득일부터 보유·거주기간을 기산합니다.

1세대 1주택 비과세 보유·거주기간 재기산 제도는 2021년 1월 1일 이후 1세대가 2주택 이상을 보유한 경우에만 적용됩니다. 따라서 2020년 12월 31일 이전에 다주택을 처분하고 2021년 1월 1일 현재 1주택만을 보유하고 있는 경우에는 주택의 취득일부터 보유·거주기간을 기산합니다.

2021년 1월 1일 이후 다주택을 처분하고 남은 최종 1주택을 2022년 5월 9일 이전에 양도하는 경우 양도소득세 계산 시 다주택에서 전환을 체크하고 추가정보를 입력하시기 바랍니다.

## 기본공제

연 1회 양도소득금액에서 250만원을 공제받을 수 있습니다. 기본공제는 납세의무자 별로 적용되기 때문에 양도하는 자산이 공동명의 자산인 경우에는 명의자 각각 기본공제를 받을 수 있습니다.

양도소득세를 산정하기 위한 참고용 계산기입니다. 실제 신고·납부해야 하는 양도소득세와 차이가 있을 수 있습니다.`,
  특례안내: `## 상생임대주택

직전임대차계약 대비 임대보증금 또는 임대료의 증가율이 5% 이하인 상생임대차계약을 체결하고 2년 이상 임대하면서 아래의 요건을 충족한 상생임대주택을 양도하는 경우 거주기간의 제한을 받지 않고 1세대 1주택 비과세 특례와 임대사업자의 거주주택 비과세 특례, 그리고 1세대 1주택 장기보유특별공제가 적용됩니다. - 소득세법 시행령 제155조의3

#### 상생임대차계약 : 직전임대차계약 대비 임대보증금 또는 임대료의 증가율이 5% 이하인 신규 또는 갱신 계약

① 2021년 12월 20일 ~ 2024년 12월 31일 사이 계약 체결 및 임대 개시

② 상생임대차계약에 따라 실제 임대한 기간이 2년 이상일 것

#### 직전임대차계약 : 해당 주택을 취득한 후 직접 임차인과 체결한 임대차계약

① 주택 취득 전 이미 체결된 임대차계약에 따라 임대인의 지위를 승계받은 경우 제외

② 직전임대차계약에 따라 실제 임대한 기간이 1년 6개월 이상일 것

**위의 요건을 충족하여 거주기간의 제한을 받지 않는 상생임대주택에 해당하면 양도소득세 계산 시 대상주택을 상생임대주택으로 선택하여 계산하시기 바랍니다.**

## 농어촌주택·고향주택 취득자의 일반주택 비과세

일반주택인 1주택과 아래의 요건 등을 충족한 농어촌주택·고향주택인 1주택을 보유한 세대가 농어촌주택·고향주택을 취득하기 전부터 보유하던 일반주택을 양도하는 경우 1주택을 소유한 것으로 보아 1세대 1주택 비과세 특례를 적용받을 수 있습니다. - 조세특례제한법 제99조의4 및 조세특례제한법 시행령 제99조의4

## 취득 당시 소재지 요건

① 「조세특례제한법」 제99조의4에 따른 수도권지역이나 조정대상지역 등이 아닌 지역

② 「조세특례제한법 시행령」 제99조의4에 따른 농어촌지역 또는 고향

③ 「지방자치분권 및 지역균형발전에 관한 특별법」 제2조제13호에 따른 기회발전특구

2003년 8월 1일(고향주택은 2009년 1월 1일) ~ 2025년 12월 31일 사이 취득하여 3년 이상 보유

취득 당시 기준시가 3억원(한옥은 4억원) 이하

#### 1) 농어촌주택이 일반주택과 행정구역상 같은 읍·면 또는 연접한 읍·면에 있거나 고향주택이 일반주택과 같은 시 또는 연접한 시에 있는 경우에는 비과세 특례가 적용되지 않으니 유의하시기 바랍니다.

#### 2) 의무보유기간 3년을 충족하기 전에 일반주택을 양도하여도 1세대 1주택 비과세 특례를 우선 적용받을 수 있습니다. 다만 의무보유기간을 충족하지 못하고 농어촌주택·고향주택을 처분하게 될 때에는 특례를 적용받아 실제 납부한 세액과 일반주택 양도 당시 이를 1주택으로 보지 않았을 경우 납부하였을 세액과의 차액을 신고·납부하여야 하니 유의하시기 바랍니다.

법정요건을 충족하여 1주택으로 보는 일반주택에 해당하면 양도소득세 계산 시 대상주택을 1주택으로 선택하여 계산하시기 바랍니다.`,
  세법개정: `**2023년 개정된 소득세 법령과 2024년 1월·2월 개정된 소득세법 및 소득세법 시행령이 양도소득세에 미치는 영향을 정리하였으니 참고하시기 바랍니다.**

## 개정내용 적용시기

소득세법 및 소득세법 시행령의 개정사항은 시행일자 이후 양도분부터 적용되는 내용과 시행일자 이후 취득분 또는 증여분부터 적용되는 내용 등으로 구분되어 있으니 유의하시기 바랍니다.

## 다주택자에 대한 양도소득세 중과 한시 배제[2022년 5월 10일 ~ 2025년 5월 9일 양도분에 적용]

양도소득세 중과를 한시적으로 배제하는 제도의 일몰기한이 기존 2024년 5월 9일에서 2025년 5월 9일까지로 1년 연장되었습니다. 이에 따라 다주택자가 보유기간이 2년 이상인 조정대상지역 소재 주택을 2022년 5월 10일부터 2025년 5월 9일 사이에 양도하는 경우에는 양도소득세가 중과되지 않습니다. 중과 배제되는 주택에는 기본세율(6% ~ 45%)이 적용되며, 보유기간이 3년 이상이라면 장기보유특별공제(6% ~ 30%, 보유기간에 따라 연 2%)도 적용됩니다.

## 다주택자 중과배제 주택 추가[2024년 2월 29일 양도분부터 적용]

법정요건을 충족하는 소형신축주택(전용면적 60제곱미터 이하, 취득가액 수도권 6억원·비수도권 3억원 이하)과 준공후미분양주택(전용면적 85제곱미터 이하, 취득가액 6억원 이하, 비수도권 소재)을 2024년 1월 10일부터 2025년 12월 31일 사이 취득한 경우 양도소득세 중과를 배제하고 주택 수 산정에서도 제외하는 특례가 신설되었습니다.

## 일시적 1세대 2주택 등 비과세 요건 완화[2023년 1월 12일 양도분부터 적용]

#### 1) 일시적 1세대 2주택의 비과세 요건 중 종전주택과 신규주택 모두 조정대상지역일 때의 종전주택 양도기한이 기존 신규주택을 취득한 날부터 2년 이내에서 3년 이내로 완화되었습니다. 이에 따라 종전주택을 2023년 1월 12일 이후 양도하는 경우에는 종전주택과 신규주택의 조정대상지역 여부와 관계없이 3년의 중복보유기간(종전주택 양도기한)이 적용됩니다.

#### 2) 1주택을 보유하던 중 1입주권 또는 1분양권을 취득하고 해당 입주권·분양권의 취득일로부터 3년 이상이 지나 종전주택을 양도하는 경우의 비과세 요건 중 
① 신규주택 — 입주권·분양권에 따라 취득하는 주택 — 으로의 전입기한과 ② 종전주택 양도기한이 기존 신규주택이 완성된 후 2년 이내에서 3년 이내로 완화되었습니다.

#### 3) 보유하고 있는 1주택의 재개발·재건축 등 사업시행기간 동안 취득한 대체주택의 비과세 요건 중 
① 신규주택 — 재개발·재건축 등 사업의 관리처분계획 등에 따라 취득하는 주택 — 으로의 전입기한과 ② 대체주택의 양도기한이 기존 신규주택이 완성된 후 2년 이내에서 3년 이내로 완화되었습니다.

## 상생임대주택 특례 임대기간 합산 규정 신설[2023년 2월 28일 양도분부터 적용]

임대차계약 도중 임차인의 사정으로 임대를 계속할 수 없게 된 경우 종전계약보다 보증금과 임대료를 낮추는 등 기획재정부령에서 정하는 요건에 따라 새로운 임차인과 신규계약을 체결한다면 종전·신규계약의 임대기간을 합산하여 직전임대차계약 또는 상생임대차계약의 임대기간 요건 충족 여부를 판단하는 임대기간 합산 규정이 신설되었습니다.

## 소득세 과세표준 구간 조정[2023년 1월 1일 양도분부터 적용]

서민·중산층의 세부담 완화를 위해 하위 2개 과세표준 구간이 기존 1200만원 이하, 1200만원 ~ 4600만원 이하에서 각각 1400만원 이하, 1400만원 ~ 5000만원 이하로 상향 조정되었습니다.

## 이월과세 및 부당행위계산의 부인 적용기간 확대[2023년 1월 1일 증여분부터 적용]

배우자나 직계존비속으로부터 증여받은 자산을 양도하는 경우 증여자의 취득가액을 기준으로 양도차익을 계산하는 이월과세 제도와 특수관계인과의 거래로 조세 부담을 부당하게 감소시킨 것으로 인정되는 경우 증여자의 취득가액을 기준으로 양도차익을 계산하는 부당행위계산 부인 제도의 적용기간이 기존 5년에서 10년으로 확대되었습니다.

## 상생임대주택 특례 요건 완화 및 혜택 확대[2022년 8월 2일 양도분부터 적용]

상생임대주택 제도의 활성화를 위해 임대개시 시점 1세대 1주택 요건 및 주택가액 기준이 폐지되었고, ① 1세대 1주택 비과세, ② 임대사업자의 거주주택 비과세, 그리고 ③ 1세대 1주택 장기보유특별공제 적용 시 거주기간 2년의 제한을 받지 않도록 혜택이 확대되었습니다. 또한 상생임대차계약의 체결 기한이 기존 2022년 12월 31일에서 2024년 12월 31일까지로 연장되었습니다.

## 1세대 1주택 비과세 보유·거주기간 재기산 제도 폐지[2022년 5월 10일 양도분부터 적용]

다주택자가 1주택을 제외한 모든 주택을 처분하고 남은 1주택을 양도하는 경우 최종적으로 1주택을 보유하게 된 날부터 보유·거주기간을 다시 기산하는 제도가 폐지되었습니다. 2022년 5월 10일 양도분부터는 최종적으로 1주택을 보유하게 된 날이 아닌 주택의 취득일부터 주택을 실제 보유·거주한 기간을 기준으로 1세대 1주택 비과세를 적용합니다.

## 일시적 1세대 2주택 비과세 요건 완화[2022년 5월 10일 양도분부터 적용]

종전주택과 신규주택 모두 조정대상지역인 경우의 일시적 1세대 2주택 비과세 요건이 완화되었습니다. 2022년 5월 10일 양도분부터 기존의 1년보다 완화된 2년의 중복보유기간(종전주택 양도기한)이 적용되며, 신규주택으로 전입 요건이 삭제되어 더는 적용되지 않습니다.

## 1세대 1주택 및 1세대 1조합원입주권 비과세 기준금액 상향[2021년 12월 8일 양도분부터 적용]

1세대 1주택 및 1세대 1조합원입주권의 비과세 기준금액이 기존 9억원에서 12억원으로 상향되었습니다. 상향된 비과세 기준금액은 2021년 12월 8일 이후 양도하는 주택 및 조합원입주권부터 적용되니 유의하시기 바랍니다.

## 1세대 1조합원입주권에 대한 비과세 요건 정비[2022년 1월 1일 취득분부터 적용]

**납세의무자의 예측가능성을 제고하기 위해 1세대 1조합원입주권에 대한 비과세 요건에 다른 분양권을 보유하고 있지 않을 것을 구체적으로 명시하였습니다. 또한 적용대상이 되는 분양권을 2022년 1월 1일 이후 신규 취득하는 분양권부터로 한정하였으니 유의하시기 바랍니다.**`,
  임대사업자: `## 장기임대주택이란?

소득세법에서의 장기임대주택은 「소득세법」 제168조에 따른 사업자등록 및 「민간임대주택에 관한 특별법」 제5조에 따른 임대사업자 등록(단기 또는 장기일반)을 한 거주자가 민간임대주택으로 등록하여 임대하는 주택으로 「소득세법 시행령」 제167조의3 제1항제2호 가목부터 바목에서 정하고 있는 요건을 충족하는 주택을 뜻합니다. 가장 대표적인 장기임대주택인 민간매입임대주택의 요건은 아래와 같습니다. - 소득세법 시행령 제167조의3

- 단기민간임대주택으로 등록하여 5년 이상 임대(2018년 3월 31일까지 사업자등록을 한 주택으로 한정)
- 장기일반민간임대주택으로 등록하여 8년 이상 임대(2020년 8월 18일 이후 임대등록을 한 주택은 10년 이상)
- 임대개시일 당시 기준시가 6억원(비수도권 3억원) 이하
- 임대보증금 또는 임대료의 증가율이 5% 이하이며 현재 임대 중

**지난 2020년 7월 10일 발표된 7.10 부동산대책과 이에 대한 후속조치로 개정된 「민간임대주택에 관한 특별법」에 따라 2020년 7월 11일 이후 폐지된 유형의 임대주택으로 등록하거나 단기임대주택을 장기로 전환할 때는 세제지원이 적용되지 않으니 유의하시기 바랍니다.**

## 임대사업자의 거주주택 비과세

「소득세법 시행령」 제167조의3 제1항제2호에 따른 장기임대주택과 그 밖의 거주주택인 1주택을 소유한 세대가 보유기간 중 2년 이상 거주한 거주주택을 양도하는 경우 생애 한 차례만 1주택을 소유한 것으로 보아 1세대 1주택 비과세 특례를 적용받을 수 있습니다. - 소득세법 시행령 제155조

임대의무기간을 충족하기 전에 거주주택을 양도하여도 1세대 1주택 비과세 특례를 우선 적용받을 수 있습니다. 다만 임대의무기간을 충족하지 못하고 임대주택을 처분하게 될 때에는 특례를 적용받아 실제 납부한 세액과 거주주택 양도 당시 이를 1주택으로 보지 않았을 경우 납부하였을 세액과의 차액을 신고·납부하여야 하니 유의하시기 바랍니다.

지난 2020년 7월 10일 발표된 7.10 부동산대책과 이에 대한 후속조치로 개정된 「민간임대주택에 관한 특별법」에 따라 아파트 장기일반매입임대주택 유형이 폐지되었습니다. 따라서 2020년 7월 11일 이후 아파트를 장기일반민간임대주택으로 등록한 때에는 거주주택에 대한 비과세가 적용되지 않습니다.

1세대가 1주택 이상을 보유한 상태에서 새로 취득한 조정대상지역에 있는 주택을 2020년 7월 11일 이후 장기일반민간임대주택으로 등록하는 경우에는 거주주택에 대한 비과세가 적용되지 않습니다. 단, 2018년 9월 13일 이전 또는 조정대상지역의 공고가 있기 전 주택을 취득하거나 취득하기 위해 매매계약을 체결하고 계약금을 지급하였다면 2020년 7월 11일 이후 임대등록하여도 거주주택에 대한 비과세를 적용받을 수 있습니다.

「민간임대주택에 관한 특별법」 개정에 따른 자진·자동등록말소로 임대의무기간을 충족하지 못하더라도 임대주택 등록말소 후 5년 내 거주주택을 양도하는 경우에는 거주주택에 대한 비과세를 적용받을 수 있습니다.

위의 요건을 충족하여 1주택으로 보는 거주주택에 해당하면 양도소득세 계산 시 대상주택을 1주택으로 선택하여 계산하시기 바랍니다. 

## 1주택인 임대주택 비과세

임대주택 외 다른 주택을 소유하고 있지 않으면 해당 임대주택이 조정대상지역에 위치하더라도 거주기간의 제한을 받지 않고 1세대 1주택 비과세 특례가 적용됩니다. 거주기간의 제한을 받지 않기 위해선 2019년 12월 16일 이전 「소득세법」 제168조에 따른 사업자등록 및 「민간임대주택에 관한 특별법」 제5조에 따른 임대사업자 등록을 하여 임대하는 주택이어야 합니다. 또한 임대의무기간을 준수하고 임대보증금 또는 임대료의 증가율이 5%를 초과하지 않아야 합니다. - 구 소득세법 시행령(법률 제30395호로 개정되기 전의 것) 제154조

지난 2019년 12월 16일 발표된 12.16 부동산대책과 이에 대한 후속조치로 개정된 「소득세법 시행령」에 따라 2019년 12월 17일 이후 임대등록하는 조정대상지역의 임대주택은 거주기간이 2년 이상이어야 1세대 1주택 비과세 특례가 적용됩니다.

위의 요건을 충족하여 거주기간의 제한을 받지 않는 1세대 1주택인 임대주택에 해당하면 양도소득세 계산 시 대상주택을 1주택 보유·거주요건 예외로 선택하여 계산하시기 바랍니다.

## 다주택 세대의 임대주택 양도소득세 중과

다주택 세대가 조정대상지역에 있는 임대주택을 양도하는 경우 임대등록 시기와 취득 시기에 따라 양도소득세 중과 여부가 차이 납니다. 지난 2017년 12월 13일 발표되었던 임대주택 등록 활성화 방안으로 8년 이상 임대사업자를 중심으로 감면 혜택이 강화되었고, 2018년 9월 13일 발표되었던 9.13 부동산대책으로 임대주택에 대한 양도소득세 중과 배제가 폐지되었으니 유의하시기 바랍니다. 단, 2018년 9월 13일 이전에 주택(분양권 및 조합원입주권 포함) 매매계약을 체결하고 계약금을 지급하였다면 2018년 9월 14일 이후 취득하였더라도 양도소득세 중과가 배제됩니다. - 소득세법 시행령 제167조의3

「민간임대주택에 관한 특별법」 개정에 따른 자동등록말소로 임대의무기간을 충족하지 못하는 경우는 예외적으로 양도소득세 중과가 배제됩니다. 자진등록말소는 임대의무기간의 1/2 이상을 임대하고, 임대주택 등록말소 후 1년 내 임대주택을 양도하는 때에만 양도소득세 중과가 배제됩니다.

#### 1) 2018년 3월 31일 이전 사업자등록

5년 이상 임대 장기임대주택(단기민간) : 양도소득세 중과 배제
8년 이상 임대 장기임대주택(장기일반민간) : 양도소득세 중과 배제

#### 2) 2018년 4월 1일 이후 사업자등록

5년 이상 임대 장기임대주택(단기민간) : 양도소득세 중과
8년 이상 임대 장기임대주택(장기일반민간) : 양도소득세 중과 배제

#### 3) 2018년 9월 14일 이후 취득

5년 이상 임대 장기임대주택(단기민간) : 양도소득세 중과
8년 이상 임대 장기임대주택(장기일반민간) : 양도소득세 중과

법정요건을 충족하여 양도소득세 중과가 배제되는 주택에 해당하면 양도소득세 계산 시 대상주택을 중과 제외대상 주택으로 선택하여 계산하시기 바랍니다.


## 임대사업자 양도소득세 혜택

#### 1) 장기일반민간임대주택 장기보유특별공제 50%·70% 적용

8년 또는 10년 이상 계속하여 임대하면서 아래의 요건을 충족한 장기일반민간임대주택을 양도하는 경우 임대기간 중 발생하는 양도차익에 대해서는 일반 장기보유특별공제 대신 50% 또는 70%의 장기보유특별공제가 적용됩니다. 단, 2020년 7월 11일 이후 장기일반민간임대주택으로 등록한 아파트와 단기에서 장기일반민간임대주택으로 변경 신고한 임대주택에 대해서는 위 특례 규정이 적용되지 않으니 유의하시기 바랍니다. - 조세특례제한법 제97조의3

### 민간건설임대주택을 장기일반민간임대주택으로 등록

① 2022년 12월 31일까지 등록하고 8년 또는 10년 이상 임대
② 2024년 12월 31일까지 등록하고 10년 이상 임대

- 전용면적 85제곱미터(수도권이 아닌 읍·면 소재 주택은 100제곱미터) 이하
- 2018년 9월 14일 이후 취득분부터 임대개시일 당시 기준시가 6억원(비수도권 3억원) 이하
- 임대보증금 또는 임대료의 5% 증액 제한을 준수

위의 요건을 충족하여 장기보유특별공제 50%·70%를 적용받는다면 양도소득세 계산 시 임대유형에서 장기일반민간 > 장기보유특별공제 50%·70% - 조특법 제97조의3을 체크하고 계산하시기 바랍니다.

#### 2) 장기일반민간임대주택 양도소득세 감면

10년 이상 계속하여 임대하면서 아래의 요건을 충족한 장기일반민간임대주택을 양도하는 경우 임대기간 중 발생한 양도소득에 대한 양도소득세를 100% 감면받을 수 있습니다. 이때 임대기간 중 발생한 양도소득은 취득일과 임대만료일, 그리고 양도일의 기준시가로 산정합니다. - 조세특례제한법 제97조의5

- 2018년 12월 31일까지 취득한 주택(2018년 12월 31일까지 매매계약을 체결하고 계약금을 납부한 경우 포함)
- 취득일로부터 3개월 이내 장기일반민간임대주택으로 등록
- 전용면적 85제곱미터(수도권이 아닌 읍·면 소재 주택은 100제곱미터) 이하
- 2018년 9월 14일 이후 취득분부터 임대개시일 당시 기준시가 6억원(비수도권 3억원) 이하
- 임대보증금 또는 임대료의 5% 증액 제한을 준수

위의 요건을 충족하여 양도소득세 감면을 받을 수 있다면 양도소득세 계산 시 임대유형에서 장기일반민간 > 임대기간 양도소득 감면 - 조특법 제97조의5를 체크하고 계산하시기 바랍니다.

**단, 위 1) 장기일반민간임대주택 장기보유특별공제 50%·70% 적용과 2) 장기일반민간임대주택 양도소득세 감면에 대하여 2018년 9월 13일 이전에 주택(분양권 및 조합원입주권 포함) 매매계약을 체결하고 계약금을 지급하였다면 2018년 9월 14일 이후 취득하였더라도 기준시가의 제한을 받지 않습니다.**

#### 3) 장기임대주택 장기보유특별공제 추가공제

6년 이상 임대한 장기임대주택을 양도하는 경우 보유기간에 따른 공제율에 임대기간에 따른 추가공제율(2% ~ 10%)을 더한 공제율로 장기보유특별공제가 적용됩니다. - 조세특례제한법 제97조의4

위의 요건을 충족하여 합산공제율을 적용받는다면 양도소득세 계산 시 임대유형에서 단기민간 > 장기임대주택 추가공제 - 조특법 제97조의4를 체크하고 계산하시기 바랍니다.`,
};

export const corporateCapitalGainsTaxMap = {
  설명: `## 법인 양도소득세란? 
  > 법인이 부동산 양도시 그 차익에 대해 부과되는 세금을 간단히 계산해볼 수 있습니다.
법인은 개인과 다르게 소득세법상의 양도소득세가 아닌 법인세법상의 법인세율을 적용받습니다.`,
};

export const deemedRentMap = {
  설명: `## 간단 설명
  부동산을 임대하고 보증금·전세금 등을 받은 경우 이에 대해 일정 이자율을 곱하여 임대수입이 있는 것으로 계산해야 합니다. 이 수입은 소득세 계산시 포함시켜야 합니다.

주택의 경우 보증금이 3억원 이상인 경우에만 해당됩니다. 상가의 경우 금액에 무관하게 모두 포함됩니다. 주택과 상가는 한번에 계산될 수 없으니 구분해서 계산해주세요.`,

  "조건 및 산식": `## 조건 및 산식
  주택을 3채 이상 보유(부부 합산)하고 보증금 등의 합계액이 3억원을 초과하는 경우, 보증금 등에 대한 간주임대료를 임대사업의 총수입에 포함시켜야 합니다.

- 간주임대료 = (보증금 등 - 3억 원) * 60% * 대상기간일수 / 365(윤년 366) * 2.9(기획재정부 발표 정기예금이자율)

- 소득세법 제25조, 소득세법 시행령 제53조`,
  정기예금이자율: `## 정기예금이자율
  
  법정 간주임대료 계산에는 기획재정부에서 고시하는 이자율을 이용합니다. 23년 간주임대요율은 2.9%입니다.
24년 간주임대료율은 3.5%입니다. 24년 간주임대료율은 6월부터 기본값으로 설정됩니다`,
};

export const rentalYieldMap = {
  설명: `
##  임대수익률이란? 
주택, 건물이나 토지 등 부동산 매입을 위해 투자한 금액과 이를 타인에게 임대하여 거둔 이익의 비율을 임대수익률이라고 합니다. 임대수익률은 수익형 부동산의 투자 적정성을 판단하는 주요 지표 중 하나로 여러 투자안의 수익성을 비교하거나 목표수익률과 비교하여 투자 적합 여부를 판단하는 데 활용됩니다. 임대수익률 계산기를 통해 현재 계획 중이거나 운영 중인 투자안의 적정성을 판단해보시기 바랍니다.

##  매매수익률
부동산 매입을 위해 투자한 금액과 매입 이후 시세 변동에 따라 거둔 이익의 비율을 매매수익률이라고 합니다. 부동산의 보유기간 동안 실현되는 임대수익과 다르게 매매수익은 부동산의 매도시점에 실현됩니다. 부동산 투자로 거둘 수 있는 이익은 보유기간에 걸쳐 발생하는 임대수익과 매도시점에 실현되는 매매수익으로 구분되기 때문에 이를 종합적으로 고려하여 투자 여부를 결정하여야 합니다.

##  레버리지 효과
대출 등 타인의 자본을 활용하며 자기자본의 수익률을 극대화하는 것을 레버리지 효과 또는 지렛대 효과라고 합니다. 타인자본을 활용하는 경우 그만큼 투입해야 하는 자기자본은 줄어들므로 같은 수익이 발생하더라도 자기자본 대비 수익률은 증가하게 됩니다.

### 예) 오피스텔을 2억원에 매입하여 보증금 1,000만원에 월세 80만원으로 임대하는 경우의 임대수익률

#### - 대출 없이 전액 자기자본으로 투자 : 연 5.05%

#### - 1억원을 4% 금리로 대출받아 투자 : 연 6.22%

레버리지를 적절하게 활용하면 수익률을 높일 수 있습니다. 다만 반대로 투자안에서 손실이 발생하면 손실률 또한 높아지므로 레버리지 효과를 흔히 양날의 검에 비유하기도 합니다. 대출 등 타인자본을 활용하기에 앞서 부동산 시장의 동향과 경제 상황 등을 고루 살펴보고 현명한 판단을 내리시기 바랍니다.`,
  주의사항: `
  ## 주의사항
  **정확한 임대수익률을 산출하기 위해 계산기 이용 시 반드시 고려해야 할 사항들을 정리하였으니 참고하시기 바랍니다.**

## 부대비용

투자대상을 매입하고 임대를 준비하는 과정에서 발생하는 비용은 부대비용으로 분류하여 계산하시기 바랍니다. 부대비용은 매입가액과 함께 투자금액을 구성하여 수익률에 영향을 주기 때문에 빠짐없이 포함하여 계산해야 정확한 수익률을 산출할 수 있습니다.


#### - 매입 과정에서 발생하는 비용 : 취득세, 공인중개사와 법무사 수수료, 등기비용 등

#### - 임대 준비 과정에서 발생하는 비용 : 노후시설 수리비용, 인테리어· 리모델링 비용 등

## 운영비용

투자대상을 임대하는 과정에서 발생하는 비용은 운영비용으로 분류하여 계산하시기 바랍니다. 매년 정기적으로 발생하는 운영비용은 연간비용으로, 그리고 매월 정기적으로 발생하는 운영비용은 월간비용으로 분류하고 비정기적으로 발생하는 운영비용은 연간비용 또는 월간비용으로 환산하여 빠짐없이 포함해야 정확한 수익률을 산출할 수 있습니다.

#### - 매년 정기적으로 발생하는 운영비용 : 재산세, 종합부동산세 등

#### - 매월 정기적으로 발생하는 운영비용 : 청소비용이나 도배비용과 같은 유지관리비, 보험료 등

**수익형 부동산 운영에 따른 임대수익률을 산정하기 위한 참고용 계산기입니다. 실제 수익률과 차이가 있을 수 있습니다.**`,
};

export const appropriatePriceMap = {
  설명: `## 간단 설명
  수익형 부동산 매입 시 목표 수익률에 기반하여 적정 부동산 매입가격을 계산합니다. 임대수익률 계산기를 역으로 계산하는 계산기입니다. 경매 입찰 시 입찰가격으로도 활용할 수 있습니다.`,
};
