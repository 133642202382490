import { useState } from "react";
import { Link } from "react-router-dom";
import { notReadyAlert } from "../../hooks/notReadyAlert";

function BugerMenu({ isOpen, closeMenu }) {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [openSubSubMenu, setOpenSubSubMenu] = useState(null);

  const toggleSubMenu = (menuName) => {
    setOpenSubMenu(openSubMenu === menuName ? null : menuName);
  };

  const toggleSubSubMenu = (menuName) => {
    setOpenSubSubMenu(openSubSubMenu === menuName ? null : menuName);
  };

  return (
    <>
      {isOpen && (
        <div className="buger_side_menu_overlay" onClick={closeMenu}></div>
      )}

      <div className={`buger_side_menu ${isOpen ? "open" : ""}`}>
        <button
          className="buger_close_button"
          onClick={closeMenu}
          aria-label="Close menu"
        >
          &times;
        </button>
        <nav className="buger_side_menu_nav">
          <div className="buger_menu_item">
            <button
              className="buger_menu_button"
              onClick={() => toggleSubMenu("calculator")}
              aria-expanded={openSubMenu === "calculator"}
            >
              부동산 계산기
            </button>
            <ul
              className={`buger_sub_menu_list ${
                openSubMenu === "calculator" ? "open" : ""
              }`}
            >
              <li>
                <Link
                  to="/calc/Brokerage"
                  className="buger_sub_menu_button"
                  onClick={closeMenu}
                >
                  중개보수
                </Link>
              </li>

              <li>
                <button
                  className="buger_sub_menu_button"
                  onClick={() => toggleSubSubMenu("transferTax")}
                  aria-expanded={openSubSubMenu === "transferTax"}
                >
                  양도세
                </button>
                <ul
                  className={`buger_sub_sub_menu_list ${
                    openSubSubMenu === "transferTax" ? "open" : ""
                  }`}
                >
                  <li>
                    <Link
                      to="/calc/CapitalGainsTax"
                      className="buger_sub_menu_button"
                      onClick={closeMenu}
                    >
                      양도세
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/calc/CoporateCapitalGainsTax"
                      className="buger_sub_menu_button"
                      onClick={closeMenu}
                    >
                      법인 양도세
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="/calc/HoldingTax"
                  className="buger_sub_menu_button"
                  onClick={closeMenu}
                >
                  보유세
                </Link>
              </li>
              <li>
                <Link
                  to="/calc/AcquisitionTax"
                  className="buger_sub_menu_button"
                  onClick={closeMenu}
                >
                  취득세
                </Link>
              </li>
              <li>
                <button
                  className="buger_sub_menu_button"
                  onClick={() => toggleSubSubMenu("rental")}
                  aria-expanded={openSubSubMenu === "rental"}
                >
                  임대 투자
                </button>
                <ul
                  className={`buger_sub_sub_menu_list ${
                    openSubSubMenu === "rental" ? "open" : ""
                  }`}
                >
                  <li>
                    <Link
                      className="buger_sub_menu_button"
                      to="/calc/RentalYield" onClick={closeMenu}
                    >
                      임대수익률
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="buger_sub_menu_button"
                      to="/calc/AppropriatePrice" onClick={closeMenu}
                    >
                      적정매수가
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="buger_sub_menu_button"
                      to="/calc/DeemedRent" onClick={closeMenu}
                    >
                      간주임대료
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <button
                  className="buger_sub_menu_button"
                  onClick={() => toggleSubSubMenu("interest")}
                  aria-expanded={openSubSubMenu === "interest"}
                >
                  예금 및 대출금리
                </button>
                <ul
                  className={`buger_sub_sub_menu_list ${
                    openSubSubMenu === "interest" ? "open" : ""
                  }`}
                >
                  <li>
                    <Link
                      to="/rate/DepositRate"
                      className="buger_sub_menu_button"
                      onClick={closeMenu}
                    >
                      예금금리
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/rate/LoanRate"
                      className="buger_sub_menu_button"
                      onClick={closeMenu}
                    >
                      대출금리
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <Link onClick={notReadyAlert}>프라임지분거래소</Link>
          <a
            href="https://survey.returnplus.kr/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={closeMenu}
          >
            셀프투자유형분석
          </a>
          <Link onClick={notReadyAlert}>투자・자산 커뮤니티</Link>
        </nav>
      </div>
    </>
  );
}

export default BugerMenu;
