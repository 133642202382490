import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
/**
 * @namespace 은행금리
 */
function BankPageComponents() {
  const { id } = useParams();
  const [Component, setComponent] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const loadComponent = async (componentName) => {
      try {
        const { default: loadedComponent } = await import(
          `../${componentName}/${componentName}`
        );
        setComponent(() => loadedComponent);
      } catch (error) {
        setComponent("");
      }
    };
    loadComponent(id);
  }, [id]);
  return (
    <div className="container_bankPageComponent">
      <div className="wrapper_bankPageComponent">
        {Component && <Component />}
      </div>
    </div>
  );
}
export default BankPageComponents;
